<template>
    <main>
        <div class="card-header bg-light "><span><i class="fa fa-list"> </i> Habitos Orales</span> </div>
            <div class="card-body">
            <div class="row mt-2">
                <div class="list-group mb-3 col-lg-4 col-md-12 col-sm-12" v-for="(item) in data" :key="item.id">
                    <div class="list-group-item">
                        <div class=" ">
                            <span class="font-weight-bolder">{{ item.habito.descripcion }}</span>
                            <p>{{item.valor}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['data'],
}
</script>