<template>
    <main ref="resumen" id="resumen">
        <div class="card mt-2 shadow-none" v-if="isLoading">
            <div class="card-body">
                <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                    <content-placeholders-text :lines="3" />
                </content-placeholders>
            </div>
        </div>
        <div v-else>
            <div class="py-2">
                <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.usuario.habitos_orales">
                <habitosOralesShow :idHistoria="idHistoria" :data="resumen.usuario.habitos_orales"></habitosOralesShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.usuario.higiene_oral">
                <higieneOralShow :idHistoria="idHistoria" :data="resumen.usuario.higiene_oral"></higieneOralShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.impresion_diagnostica">
                <impresionDxShow :idHistoria="idHistoria" :data="resumen.impresion_diagnostica"></impresionDxShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.ordenes">
                <ordenClinicaShow :idHistoria="idHistoria" :data="resumen.ordenes"></ordenClinicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.formulas">
                <formulaMedicaShow :idHistoria="idHistoria" :data="resumen.formulas"></formulaMedicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.recomendaciones">
                <recomendacionesShow :idHistoria="idHistoria" :data="resumen.recomendaciones"></recomendacionesShow>
            </div>
        </div>
    </main>
</template>

<script>
import motivoConsultaShow from "../base/motivoConsulta/motivoConsultaShow";
import impresionDxShow from "../base/impresionDX/impresionDxShow";
import historiaClinicaService from "../../../services/historiaClinicaService";
import recomendacionesShow from "../base/recomendaciones/recomendacionesShow";
import ordenClinicaShow from "../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../base/formulaMedica/formulaMedicaShow";
import higieneOralShow from "../base/baseHigieneOral/show";
import habitosOralesShow from "../base/baseHabitosOrales/show";
export default {
    props: ["idHistoria", "idUsuario"],
    components: {
        motivoConsultaShow,
        recomendacionesShow,
        impresionDxShow,
        ordenClinicaShow,
        formulaMedicaShow,
        higieneOralShow,
        habitosOralesShow
    },
    data() {
        return {
            resumen: {},
            isLoading: true,
        };
    },

    async created() {
        this.isLoading = true;
        const response = await historiaClinicaService.resumenHc(this.idHistoria);
        this.resumen = response.data;
        this.isLoading = false;
    },
};
</script>

<style scoped>
.resumen-container {
    height: 300px;
    overflow-y: scroll;
}
</style>
